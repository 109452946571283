import routerFactory from "@/client/router";
import _ from "lodash/object";

import { reactive, computed, getCurrentInstance } from "vue";

import { VuelidatePlugin } from "@vuelidate/core";
import * as validators from "@vuelidate/validators";
import {
  validators as customValidators,
  validatorFactories,
} from "@/client/extensions/validation/index.js";

const getValidator = (name, params, store) => {
  if (Object.keys(customValidators).includes(name)) {
    return customValidators[name];
  }

  // validation not found
  if (!Object.keys(validators).includes(name)) {
    return false;
  }

  // validation found in vuelidate, use it
  return validators[name];
};

const getValidatorFactory = (name, params, store, validationContext = {}) => {
  // overload store, validationContext
  if (params.params && typeof params.params === "object") {
    params.params.store = store;
    params.params.context = validationContext;
  }


  if (Object.keys(validatorFactories).includes(name)) {
    let result = validatorFactories[name];
    result.isFactory = true;
    result.store = store;
    return result;
  }

  return false;
};

export default (props, store = false) => {
  let internalInstance = getCurrentInstance();
  let component = internalInstance.proxy;

  if (internalInstance && !store) {
    store = internalInstance.proxy.$store;
  }

  /**
   * get a single validation for a field, by definition,
   * Definition structure:
   * non-object or empty object - uses key as name of validator from vuelidate, thats it
   *
   * In case of object, does the fallowing:
   * - validator determines the validator used. if function, uses function. if string, uses validator that matches name from vuelidate
   * - params will be used with the validator function. if missing, method will run with no params
   * - message - will override the validation message. this is just saved here, but will be used by other methods to show the message
   * @param definitionInput - definition of the validation for the field
   * @param key - name of the validation rule, used for some fallbacks / naming convension deduction

   */
  let getFieldValidation = (definitionInput, key, store, validationContext = {}) => {
    let definition = Object.assign({}, definitionInput),
      arrParams = [],
      params = {},
      message = "",
      validator,
      validatorName,
      translationKey;

    // if validation definition is invalid or missing, just use key to infer the validation, and make sure definition is object so we can continue

    if (typeof definition != "object" || Object.entries(definition).length < 1) {
      definition = {};
      validatorName = key;
      validator = getValidator(key, store);
    }

    // find validator if validator key is missing
    if (typeof definition.validator === "undefined") {
      validatorName = key;
      validator = getValidator(key, store);
      definition.validator = key;
    }

    // overload the definition with the store
    //  definition.store = store;

    // find validator, if the validator definition is a non-empty string
    if (typeof definition.validator === "string" && definition.validator !== "") {
      validatorName = definition.validator;

      validator = getValidatorFactory(definition.validator, definition, store, validationContext);

      if (!validator) {
        validator = getValidator(definition.validator, store, validationContext);
      }
    }

    // assign validator if the validator is actually provided
    if (typeof definition.validator === "function") {
      validator = definition.validator;
    }

    // params
    if (typeof definition.params === "object" && definition.params !== null) {
      params = definition.params;
    }

    // message
    if (typeof definition.message === "string") {
      translationKey = definition.message;
    } else {
      translationKey = "validation." + (validatorName || "unknown");
    }

    message = component.translate ? component.translate(translationKey, params || {}) : translationKey;

    // TODO: instead of serving factory validators normally (which will cause errors), make a concept of 'factory validators' in our plugins
    if (typeof validator === "function" && validator.isFactory) {
      validator = validator(params);
    }

    if (typeof params === "object") {
      arrParams = Object.values(params);
    }

    if (typeof validator === "function" && !validator.isAsync) {
      validator = validator(...arrParams);
    }

    if (!validator) {
      utilities.debug("Warning - validator not found in useValidation", 2, {
        method: getFieldValidation,
        args: { definitionInput, key },
      });
    }

    // assign the final message to the validator itself
    validator.message = message;
    validator.$message = message;

    return { validator, params, message };
  };

  // todo: this is somehow not reactive
  /**
   * Given a validation configuration, get prepared validatiors
   * @param validationConfig
   * @param simplified - should the return format be simplified
   */
  let getValidationsByConfig = (validationConfig, simplified = true, validationContext = {}) => {
    validationConfig = validationConfig || {};

    let simplifiedResult = {};
    let target = {};

    for (const [validationKey, validationValue] of Object.entries(validationConfig)) {
      target[validationKey] = getFieldValidation(validationValue, validationKey, store, validationContext);
    }

    if (!simplified) {
      return target;
    }

    // simplified result required
    for (const [index, definition] of Object.entries(target)) {
      simplifiedResult[index] = definition.validator;
    }

    return simplifiedResult;
  };

  return {
    getValidator,
    getValidatorFactory,
    getFieldValidation,
    getValidationsByConfig,
  };
};
