import throttleAsyncFunction from "@/client/utilities/throttleAsyncFunction.js";
import getAsyncOps from "@/client/extensions/composition/asyncOperations.js";
import { helpers } from "@vuelidate/validators";

export default function (options = {}) {
  let store = options.store || false;
  let validator = (value) => {
    let runValidator = async (resolveCb, rejectCb) => {
      if (!value && value !== 0 && value !== false) {
        return resolveCb(true);
      }
let requestResult;
      console.log("run", options);

      try {
        let requestData = {
          type: options.type,
          key: options.key,
          value: value,
        };

        let extractExcludeValueFromContext = () => {
          let excludeValue = undefined;

          // look for exclude value
          if (options.excludeIdKey && options.context) {
            if (typeof options?.context?.validationContext === 'object' && options?.context?.validationContext[options.excludeIdKey]) {
              excludeValue = options.context.validationContext[options.excludeIdKey];
            }

            if (
              options?.context?.form?.formData &&
              typeof options?.context?.form?.formData === "object" &&
              options?.context?.form?.formData[options.excludeIdKey]
            ) {
              excludeValue = options.context.form.formData[options.excludeIdKey];
            }
          }
          return excludeValue;
        };
        let excludeValue = extractExcludeValueFromContext();

        if (excludeValue) {
          requestData.exclude = excludeValue;
        }
        // console.log("validator request data", requestData, excludeValue);
        requestResult = await getAsyncOps({}, store).asyncCall(
          "entity/validation/is-unique",
          requestData,
          {}
        );

        if (requestResult.hasError) {
          console.log("resolve false error");
          rejectCb(false);
        }

        // exists -> validation fails
        console.log("resolve result", requestResult);
        resolveCb(!requestResult.data?.exists);
      } catch(e) {
        console.log("exception", e);
        rejectCb(false);
      }

    };

    return new Promise(async (resolve, reject) => {
      throttleAsyncFunction(runValidator, resolve, reject, options);
    });
  };

  return helpers.withParams({ type: "uniqueValue" }, helpers.withAsync(validator));
}
