import throttleAsyncFunction from "@/client/utilities/throttleAsyncFunction.js";
import getAsyncOps from "@/client/extensions/composition/asyncOperations.js";
import { helpers } from "@vuelidate/validators";

export default function (options = {}) {
  let store = options.store || false;
  let validator = (value) => {
    let runValidator = async (resolveCb, rejectCb) => {
      let requestResult = await getAsyncOps({}, store).asyncCall(
        "user/validation/email",
        { email: value },
        {}
      );

      if (requestResult.code !== 200) {
        rejectCb(false);
      } else {
        resolveCb(requestResult.data);
      }
    };

    return new Promise(async (resolve, reject) => {
      throttleAsyncFunction(runValidator, resolve, reject, options);
    });
  };

  return helpers.withParams(
    { type: "uniqueEmail" },
    helpers.withAsync(validator)
  );
}
