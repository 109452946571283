<template>
  <Skeleton
    :class="skeletonClass"
    template="form"
    :fit-height="false"
    :default-height="skeletonHeight"
    :count="expectedFields"
    :content-ready="asyncStatus.asyncDataReady">
    <form
      v-if="asyncStatus.asyncDataReady"
      class="form"
      :class="formClass"
      :method="formMethod"
      :disabled="formDisabled"
      :action="action"
      @submit.prevent="handleSubmit()"
      @reset.prevent="clear(), untouchAll()">
      <alert
        v-if="showSubmitError"
        class="form-alert margin-l-bottom"
        type="danger"
        close-type="event"
        @alert:closing="hideSubmitError">
        {{ finalGeneralErrorMessage }}
      </alert>

      <div class="fields" :class="finalFieldWrapperClass">
        <component
          :is="group.component"
          v-for="(group, groupKey) in getGroupedFields()"
          key="groupKey"
          :has-error="doesGroupHaveErrors(group)"
          v-bind="group.props">
          <component
            :is="field.component"
            v-for="(field, fieldIndex) in getGroupFieldsForRendering(group)"
            v-bind="{ themeStyle: themeStyle, ...field }"
            :key="groupKey + '-field-' + fieldIndex"
            :model-value="getVmodelTargetByField(field)"
            :error-display-position="errorDisplayPosition"
            :validation-pending="isFieldValidationPending(fieldIndex)"
            :error-message="getFieldValidationErrorMessage(fieldIndex)"
            @focusout="allowFieldErrorDisplay(fieldIndex)"
            @field:changed="(val) => setInputValue(field, val, fieldIndex)"></component>
        </component>
        <slot name="afterFields"></slot>
      </div>
      <component
        :is="group.component"
        v-for="(group, groupKey) in getGroupedButtons()"
        key="groupKey"
        v-bind="group.props">
        <component
          :is="button.component"
          v-for="(button, buttonIndex) in group.buttons"
          :key="groupKey + '-button-' + buttonIndex"
          :disabled="buttonsDisabled"
          :loading="buttonsLoadingStatus[groupKey][buttonIndex]"
          v-bind="getButtonConfig(buttonIndex)">
          {{ safeTranslate(button.text) }}
        </component>
      </component>
      <slot name="afterButtons"></slot>

      <div class="recaptcha-information text-center">
        <div
          v-show="preflightTypes.includes('recaptcha')"
          class="recaptcha-text"
          style="font-size: var(--font-min); margin-top: var(--margin-m)">
          <span>{{ forceTranslate("core.form.recaptcha.text") }}</span
          >,
          <a target="_blank" href="https://policies.google.com/privacy">{{
            forceTranslate("core.form.recaptcha.privacyPolicy")
          }}</a>
          {{ forceTranslate("core.form.recaptcha.and") }}
          <a target="_blank" href="https://policies.google.com/terms">{{
            forceTranslate("core.form.recaptcha.tos")
          }}</a>
          {{ forceTranslate("core.form.recaptcha.apply") }}
        </div>
      </div>
      <spinner
        v-if="showLoadingOverlay"
        :text="overlaySpinnerText"
        overlay="absolute"
        :show="true"></spinner>
    </form>
  </Skeleton>
</template>

<script>
const _ = require("lodash/object");

import useValidation from "@/client/extensions/composition/useValidation";
import asyncOperations from "@/client/extensions/composition/asyncOperations.js";
import useRecaptcha from "@/client/extensions/composition/useRecaptcha.js";
import useVuelidate from "@vuelidate/core";

import { reactive } from "vue";

import BaseFormMixin from "@/client/extensions/mixins/baseComponents/form/baseForm.js";

export default {
  mixins: [BaseFormMixin],
  props: {
    controlGroupHeight: {
      type: [Number],
      default: 80,
    },
  },
  setup(props, context) {
    let { asyncOps, asyncOpsReady, asyncStatus } = asyncOperations(props);
    let { isCaptchaReady, executeCaptcha } = useRecaptcha();
    return {
      v$: useVuelidate(),
      asyncOps,
      asyncOpsReady,
      asyncStatus,
      ...useValidation(props),
      isCaptchaReady,
      executeCaptcha,
    };
  },
  data: function () {
    return {
      primaryButtonDefaultClass: "button--lead",
    };
  },
  computed: {
    finalFieldWrapperClass() {
      let result = this.fieldWrapperClass;
      if (!this.fieldWrapperMargin) {
        return result;
      }

      if (this.fieldWrapperMargin === true) {
        return (result = result + " " + "margin-l-bottom");
      }
      result = result + " " + "margin-" + this.fieldWrapperMargin + "-bottom";

      return result;
    },

    skeletonHeight() {
      // 85 px for each form field, + another such unit for the button
      return (this.expectedFields + 1) * this.controlGroupHeight;
    },

    skeletonClass() {
      if (this.class) {
        return "form-skeleton form-skeleton--" + this.class + ' ' + this.class;
      }

      return "form-skeleton";
    },

    formClass() {
      let className = "";
      if (this.class) {
        className = "form " + this.class;
      }

      if (this.errorDisplayPosition === "absolute") {
        className = className + " form--errors-absolute";
      }

      className = className + " form--style-" + this.themeStyle;

      return className;
    },
  },
  watch: {
    finalConfig(newVal) {
      // console.log('watch', newVal);
    },
  },
  validations() {
    return {
      formData: this.getValidationRules(),
    };
  },
  serverPrefetch() {
    return this.getSSRPrefetchPromise();
  },
};
</script>

<style scoped lang="scss"></style>

<style lang="scss">
// NON SCOPED STYLE - we can hide recaptcha badge because we handle the legal stuff ourselves
.grecaptcha-badge {
  visibility: hidden;
}
</style>
