import throttleAsyncFunction from "@/client/utilities/throttleAsyncFunction.js";
import getAsyncOps from "@/client/extensions/composition/asyncOperations.js";
import { helpers } from "@vuelidate/validators";

export default function (options = {}) {
  let url = options.url || false;
  let data = options.data || {};
  let store = options.store || false;
  let timeout;
  let oldResolve;

  if (!url) {
    debug("no url for liveServerSide validator");
  }

  let validator = (value) => {
    // clear scheduled api calls, resolve pending validator promises, if avaialble
    clearTimeout(timeout);
    if (typeof oldResolve === "function") {
      oldResolve(true);
    }

    let runValidator = async (resolveCb, rejectCb) => {
      let requestResult = await getAsyncOps({}, store).asyncCall(
        url,
        { value, ...data },
        {}
      );

      if (requestResult.code !== 200) {
        rejectCb(false);
      } else {
        resolveCb(requestResult.data);
      }
    };

    return new Promise(async (resolve, reject) => {
      // save our resolve, so it can be called from a higher scope
      oldResolve = resolve;

      // run the actual validator, with delay. save timeout, so it can be canceled
      timeout = utilities.setClientTimeout(async () => {
        runValidator(resolve, reject);
      }, 500);
    });
  };

  return helpers.withParams(
    { type: "liveServerSide" },
    helpers.withAsync(validator)
  );
}
